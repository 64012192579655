import React from "react";
import "./Banner.css";
import "./Banner1.css";
import $ from "jquery";
import { useEffect } from "react";
import demo from "../../assets/images/banner.jpg";

const Banner = () => {

  $(document).ready(function () {
    let owl = $("#owl-demo");
    // owl.owlCarousel({
    //   items: 5, //10 items above 1000px browser width
    //   itemsDesktop: [1000, 5], //5 items between 1000px and 901px
    //   itemsDesktopSmall: [900, 3], // betweem 900px and 601px
    //   itemsTablet: [600, 2], //2 items between 600 and 0
    //   itemsMobile: false, // itemsMobile disabled - inherit from itemsTablet option
    // });

    // Custom Navigation Events
    $(".next").click(function () {
      owl.trigger("owl.next");
    });
    $(".prev").click(function () {
      owl.trigger("owl.prev");
    });
    $(".play").click(function () {
      owl.trigger("owl.play", 500); //owl.play event accept autoPlay speed as second parameter
    });
    $(".stop").click(function () {
      owl.trigger("owl.stop");
    });
  });

  let i = 2;
  $(document).ready(function () {
    let radius = 200;
    let fields = $(".itemDot");
    let container = $(".dotCircle");
    let width = container.width();
    radius = width / 2.5;

    let height = container.height();
    let angle = 0,
      step = (2 * Math.PI) / fields.length;
    fields.each(function () {
      let x = Math.round(
        width / 2 + radius * Math.cos(angle) - $(this).width() / 2
      );
      let y = Math.round(
        height / 2 + radius * Math.sin(angle) - $(this).height() / 2
      );
      // if (window.console) {
      //   console.log($(this).text(), x, y);
      // }

      $(this).css({
        left: x + "px",
        top: y + "px",
      });
      angle += step;
    });

    $(".itemDot").click(function () {
      let dataTab = $(this).data("tab");
      $(".itemDot").removeClass("active");
      $(this).addClass("active");
      $(".cirItem").removeClass("active");
      $(".cirItem" + dataTab).addClass("active");
      i = dataTab;

      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 1) * 36) + "deg)",
        transition: "2s",
      });
      $(".itemDot").css({
        transform: "rotate(" + (i - 1) * 36 + "deg)",
        transition: "1s",
      });
    });

    setInterval(function () {
      let dataTab = $(".itemDot.active").data("tab");
      if (dataTab > 6 || i > 6) {
        dataTab = 1;
        i = 1;
      }
      $(".itemDot").removeClass("active");
      $('[data-tab="' + i + '"]').addClass("active");
      $(".cirItem").removeClass("active");
      $(".cirItem" + i).addClass("active");
      i++;
      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 2) * 36) + "deg)",
        transition: "2s",
      });
      $(".itemDot").css({
        transform: "rotate(" + (i - 2) * 36 + "deg)",
        transition: "1s",
      });
    }, 5000);
  });

  return (
    <>
      <div className="agency2" id="home">
        <div className="container" id="Bannerid">
          <div className="row animatedBackground">
            <div className="col-sm-12 col-md-6">
              <div className="text-container">
                <h2>
                  <span className="red-color">Accelerate</span> your Development
                  Process
                </h2>
                <p>
                  Dependable software development to deliver integrated and
                  intelligent design, we strive to unlock business growth.
                  Offering bespoke Software development and digital
                  transformation from Conception to Delivery. Working as your
                  digital transformation partner, we work with you every step of
                  the way – from initial planning to delivery, and beyond.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <section
                className="iq-features aos-init aos-animate"
                data-aos="fade-right"
              >
                <div className="">
                  <div
                    className="align-items-center"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="holderCircle">
                        <div className="round"></div>
                        {/* <div
                          className="dotCircle "
                          // style="transform: rotate(252deg); transition: all 2s ease 0s;"
                          style={{
                            transform: "rotate(252deg)",
                            transition: "all 2s ease 0s",
                          }}
                        >
                          <span
                            className="itemDot itemDot1 "
                            data-tab="1"
                            // style="left: 435px; top: 212px; transform: rotate(108deg); transition: all 1s ease 0s;"
                         
                          >
                            <i className="bi bi-clock-fill"></i>
                            <span className="forActive"></span>
                          </span>
                          <span
                            className="itemDot itemDot2 "
                            data-tab="2"
                            // style="left: 328px; top: 392px; transform: rotate(108deg); transition: all 1s ease 0s;"
                           
                          >
                            <i className="bi bi-wechat"></i>
                            <span className="forActive"></span>
                          </span>
                          <span
                            className="itemDot itemDot3 "
                            data-tab="3"
                            // style="left: 118px; top: 392px; transform: rotate(108deg); transition: all 1s ease 0s;"
                            
                          >
                            <i className="bi bi-briefcase-fill"></i>
                            <span className="forActive"></span>
                          </span>
                          <span
                            className="itemDot itemDot4 active "
                            data-tab="4"
                            // style="left: 13px; top: 210px; transform: rotate(108deg); transition: all 1s ease 0s;"
                           
                          >
                            <i className="bi bi-tags-fill"></i>
                            <span className="forActive"></span>
                          </span>
                          <span
                            className="itemDot itemDot5 "
                            data-tab="5"
                            // style="left: 117px; top: 28px; transform: rotate(108deg); transition: all 1s ease 0s;"
                         
                          >
                            <i className="bi bi-people-fill"></i>
                            <span className="forActive"></span>
                          </span>
                          <span
                            className="itemDot itemDot6 "
                            data-tab="6"
                            // style="left: 327px; top: 28px; transform: rotate(108deg); transition: all 1s ease 0s;"
                           
                          >
                            <i className="bi bi-cloud-arrow-up"></i>
                            <span className="forActive"></span>
                          </span>
                        </div> */}
                        <div className="contentCircle">
                          <div className="cirItem title-box cirItem1">
                            <h2 className="title">
                              <span>Automation</span>
                            </h2>
                            <p>Digital transformation your business.</p>
                          </div>
                          <div className="cirItem title-box cirItem2">
                            <h2 className="title">
                              <span>Responsive </span>
                            </h2>
                            <p>
                              Continuously striving to offer better performance
                              on cross platform.
                            </p>
                          </div>
                          <div className="cirItem title-box cirItem3">
                            <h2 className="title">
                              <span>Support</span>
                            </h2>
                            <p>
                              Integrating technologies to solve complex issues.
                            </p>
                          </div>
                          <div className="cirItem title-box cirItem4 active">
                            <h2 className="title">
                              <span>Virtual Team </span>
                            </h2>
                            <p>
                              Remotely addressing your virtual environment
                              challenges.
                            </p>
                          </div>
                          <div className="cirItem title-box cirItem5">
                            <h2 className="title">
                              <span>Affordable</span>
                            </h2>
                            <p>Offering affordable services.</p>
                          </div>
                          <div className="cirItem title-box cirItem6">
                            <h2 className="title">
                              <span>Dependable</span>
                            </h2>
                            <p>
                              Creating strong online presences for your products
                              and services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

               
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
