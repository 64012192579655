import React, { useEffect, useState } from "react";
import BackToTop from "../BackToTop/BackToTop";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Subbanner from "../Subbanner/Subbanner";
import { ToastContainer, toast } from "react-toastify";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import "./Contact.css";
import Talk from "../Talk/Talk";

const Contact = () => {
  const [loader, setLoader] = useState(true);
  const [contactdata, setContactdata] = useState({
    name: "",
    email: "",
    phone_number: "",
    msg_subject: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    getloading();
  }, []);

  const getloading = () => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const handleChange = (e) => {
    setContactdata({ ...contactdata, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [formErrors]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(contactdata));
    if (
      contactdata.name !== "" &&
      contactdata.email !== "" &&
      contactdata.phone_number !== "" &&
      contactdata.msg_subject !== "" &&
      contactdata.message !== ""
    ) {
    } else {
      if (contactdata.name === "") {
        toast.error("please fill name !!");
      }
      if (contactdata.email === "") {
        toast.error("please fill email !!");
      }
      if (contactdata.phone_number === "") {
        toast.error("please fill phone number !!");
      }
      if (contactdata.msg_subject === "") {
        toast.error("please fill subject !!");
      }
      if (contactdata.message === "") {
        toast.error("please fill message !!");
      }
    }
    await addDoc(collection(db, "Contact"), {
      timestamp: serverTimestamp(),
      title: contactdata.name,
      Sub_title: contactdata.email,
      phone_number: contactdata.phone_number,
      msg_subject: contactdata.msg_subject,
      message: contactdata.message,
    })
      .then(() => {
        toast("Your message send successfully", { type: "success" });
      })
      .catch((error) => {
        toast.error("Error adding Form");
      });
    setContactdata("");
  };
  const validate = (contactdata) => {
    const errors = {};
    if (!contactdata.name) {
      errors.name = "Name is required!";
    } else if (!contactdata.email) {
      errors.email = "Email is required!!";
    } else if (!contactdata.phone_number) {
      errors.phone_number = "Phone number is required!!";
    } else if (!contactdata.msg_subject) {
      errors.msg_subject = "Subject is required!!";
    } else if (!contactdata.message) {
      errors.message = "Message is required!!";
    }
    return errors;
  };
  return (
    <>
      <Navbar />
      <ToastContainer />

      {loader && <div id="preloader"></div>}
      <Subbanner pagename={"Contact Us"} />
      <Talk />

      <section className="contact-section pb-100">
        <div className="container">
          <div className="section-title text-center contact-detail">
            {/* <span>Contact Us</span> */}
            <h2>Drop A Message For Any Query</h2>
            <p>
              It is a long established fact that a reader will be distracted
              by the readable content of a page when looking at its layout.
            </p>
          </div>
          <div className="contact-wrap pt-45">
            <div className="contact-wrap-form">
              <form id="contactForm" onSubmit={onSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        value={contactdata.name}
                        className="form-control1"
                        placeholder="Your Name"
                        onChange={(e) => handleChange(e)}
                      />
                      <p className="errorcolor"> {formErrors.name} </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value=""
                        className="form-control1"
                        placeholder="Your Email"
                        onChange={(e) => handleChange(e)}
                      />
                      <p className="errorcolor"> {formErrors.email} </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        value={contactdata.phone_number}
                        className="form-control1"
                        placeholder="Your Phone"
                        onChange={(e) => handleChange(e)}
                      />
                      <p className="errorcolor">
                        {formErrors.phone_number}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="msg_subject"
                        value={contactdata.msg_subject}
                        className="form-control1"
                        placeholder="Your Subject"
                        onChange={(e) => handleChange(e)}
                      />
                      <p className="errorcolor">{formErrors.msg_subject}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        value={contactdata.message}
                        className="form-control1"
                        cols="30"
                        rows="8"
                        placeholder="Your Message"
                        onChange={(e) => handleChange(e)}
                      ></textarea>
                      <p className="errorcolor"> {formErrors.message} </p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 text-center">
                    <button
                      type="submit"
                      className="default-btn page-btn text-center disabled"
                      style={{ pointerevents: "all", cursor: "pointer" }}
                    >
                      Send Message
                    </button>
                    <div
                      id="msgSubmit"
                      className="h3 text-center hidden"
                    ></div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop />
    </>
  )
};

export default Contact;
