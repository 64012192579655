import React from "react";
import "./Specialties.css";
import Blender from "../../assets/images/Specialties/Blender.svg";
import Photoshop from "../../assets/images/Specialties/Photoshop.svg";
import Illustrator from "../../assets/images/Specialties/Illustrator.svg";
import aftereffects from "../../assets/images/Specialties/aftereffects.svg";
import Figma from "../../assets/images/Specialties/Figma.svg";
import xd from "../../assets/images/Specialties/xd.png";

const Ui = () => {
  return (
    <div className="mt-4" data-aos="fade-up">
      <div className="justify-content-center row content_info" style={{ margin: 0 }}>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Blender}
                alt="Photoshop"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Blender
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Photoshop}
                alt="Figma"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Photoshop
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Illustrator}
                alt="Illustrator"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Illustrator
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={aftereffects}
                alt="Maya"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Adobe After Effects
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={xd}
                alt="Max"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Adobe XD
            </h4>
          </div>
        </div>
        <div className="mt-3 resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={Figma}
                alt="Blender"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Figma
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ui;
