import React, { useEffect, useState } from "react";
import BackToTop from "../BackToTop/BackToTop";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Subbanner from "../Subbanner/Subbanner";
import "./Career.css";
import Modals from "./Modal";
import BlogDataService from "../blog.services";
import c1 from "../../assets/images/c1.png";
import c2 from "../../assets/images/c2.png";
import c3 from "../../assets/images/c3.png";
import c4 from "../../assets/images/c4.png";
import c5 from "../../assets/images/c5.png";
import c6 from "../../assets/images/c6.png";
import c7 from "../../assets/images/c7.png";

const Career = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
    
    const data = await BlogDataService.getData();
    setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

  };
  console.log(data, "data");
  return (
    <>
      <Navbar />
      {loader && <div id="preloader"></div>}
      <Modals show={show} setShow={setShow} />
      <Subbanner pagename={"Career"} />
      <section id="Benefits">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="Benefits-detail">
                <div className="Benefits-details">
                  <div className="Benefits-title">
                    <div className="sort-title">Take a look at</div>
                    <h3>Perks &amp; Benefits</h3>
                  </div>
                </div>
                <div className="Benefits-text-editor ">
                  <p>
                    Our employees adore us for our work culture and friendly
                    environment and at Sunrise info – our compensation is the
                    best in the class. It is complemented with rewards for
                    high performance, perks, and benefits. Focus on
                    employees’ growth is ingrained in our work culture.
                  </p>
                  <p>When at Sunrise info, a rewarding career awaits you!</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="Benefits-part">
                <div className="elementor-widget-container">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img">
                      <img
                        width="100"
                        height="70"
                        src={c1}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content">
                      <h5 className="image-box-title">Flexible Timings</h5>
                      <p className="image-box-description">
                        Empower our employees to better manage the work-life
                        balance
                      </p>
                    </div>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img">
                      <img
                        width="35"
                        height="35"
                        src={c2}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content">
                      <h5 className="image-box-title">
                        Competitive Salary
                      </h5>
                      <p className="image-box-description">
                        A competitive compensation adds to our employees’
                        delight
                      </p>
                    </div>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img">
                      <img
                        width="100"
                        height="50"
                        src={c3}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content">
                      <h5 className="image-box-title">
                        Employee Welfare Fund
                      </h5>
                      <p className="image-box-description">
                        Our Employee Welfare Fund scheme is well thought
                        over and managed because our employees mean the
                        world to us.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img">
                      <img
                        width="40"
                        height="40"
                        src={c4}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content">
                      <h5 className="image-box-title">Health Care</h5>
                      <p className="image-box-description">
                        Addressing employees’ healthcare concerns is our
                        priority and we provide health insurance. While you
                        are working with us, we manage your healthcare
                        bills.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img">
                      <img
                        width="70"
                        height="50"
                        src={c5}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content">
                      <h5 className="image-box-title">Life Insurance</h5>
                      <p className="image-box-description">
                        We value our employees and their loved ones and
                        provide Life Insurance for each of our employees.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img">
                      <img
                        width="35"
                        height="50"
                        src={c6}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content">
                      <h5 className="image-box-title">
                        Targeted Bonus Program
                      </h5>
                      <p className="image-box-description">
                        Rewarding talent works for employees and motivates
                        them to deliver their best!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="image-box-wrapper">
                    <figure className="image-box-img">
                      <img
                        width="60"
                        height="50"
                        src={c7}
                        className="attachment-full size-full"
                        alt=""
                      />
                    </figure>
                    <div className="image-box-content">
                      <h5 className="image-box-title">
                        Annual Company Trip
                      </h5>
                      <p className="image-box-description">
                        The happiest minds are the smartest minds. Our
                        Annual Company Trips are all about employee bonding,
                        fun & frolic, and recreation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="job-offer">
        <div className="container">
          <div className="job-offer-title">
            <h3>We don’t offer Jobs. We offer Careers.</h3>
          </div>
          <div className="row">
            {data.map((doc, index) => {
              return (
                <div className="col-lg-6">
                  <div>
                    <div className="acc-item ">
                      <ul>
                        <li className="l11">
                          <a
                            className="acc-toggle collapsed"
                            href="#Questions"
                            data-bs-toggle="collapse"
                            data-bs-target={`#accordion-list-${index}`}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {doc.Skill}
                            <i className="bx bx-down-arrow-alt icon-show"></i>
                            <i className="bx bx-check icon-close"></i>
                          </a>
                          <div
                            id={`accordion-list-${index}`}
                            className="collapse acc--content"
                            data-bs-parent=".accordion-list "
                          >
                            <strong>Experience &amp; Qualification</strong>
                            <ul className="unstyled list list-check">
                              <li>{doc.Year_Exp} Year Exp.</li>
                              <li>{doc.Positions} Positions </li>
                            </ul>
                            <strong>Location</strong>
                            <ul>
                              <li>{doc.Location}</li>
                            </ul>
                            <strong>Skills Required</strong>
                            <ul>
                              {doc.Skills_Required?.map((doc, index) => {
                                return <li>{doc}</li>;
                              })}
                            </ul>
                            <div className="ot-button">
                              <button onClick={() => setShow(true)}>
                                <span>
                                  Apply now
                                  <i className="bx bx-right-arrow-alt"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop />

    </>
  );
};

export default Career;
