import React from "react";
import "./Specialties.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Frontend from "./Frontend";
import Backend from "./Backend";
import Mobile from "./Mobile";
import Database from "./Database";
import CMS from "./CMS";
import Infra from "./Infra";
import Ui from "./Ui";

const Specialties = () => {
  return (
    <>
      <div>
        <section id="Technologies" className="Technologies-wrapper">
          <div>
            <header
              className="section-header"
              data-aos="fade-down"
              // data-aos-offset="300"
              // data-aos-easing="ease-in-sine"
            >
              <h3 className="section-title">Technologies We Work With</h3>
            </header>
            <Tabs
              defaultActiveKey="Mobile"
              transition={false}
              id="noanim-tab-exampl e"
              className="mb-3 mt-3"
              data-aos="fade-down"
              // data-aos-offset="300"
              // data-aos-easing="ease-in-sine"
            >
              <Tab eventKey="Mobile" title="Mobile">
                <Mobile />
              </Tab>
              <Tab eventKey="Database" title="Database">
                <Database />
              </Tab>
              <Tab eventKey="Front End" title="Front End">
                <Frontend />
              </Tab>
              <Tab eventKey="Backend" title="Backend">
                <Backend />
              </Tab>
              <Tab eventKey="CMS" title="CMS">
                <CMS />
              </Tab>
              <Tab eventKey="UI/UX" title="UI/UX & Graphics">
                <Ui />
              </Tab>
              <Tab eventKey="Infra and Devops" title="Infra and Devops">
                <Infra />
              </Tab>
            </Tabs>
          </div>
        </section>
      </div>
    </>
  );
};

export default Specialties;
