import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Career/Mymodal.css";

const Skypemodal = ({ show, setShow }) => {
  const [contactdata, setContactdata] = useState({
    name: "",
    email: "",
    phone_no: "",
    msg_subject: "",
    message: "",
    Skype: "",
  });

  const handleChange = (e) => {
    setContactdata({ ...contactdata, [e.target.name]: e.target.value });
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    if (
      contactdata.name !== "" &&
      contactdata.email !== "" &&
      contactdata.phone_no !== "" &&
      contactdata.msg_subject !== "" &&
      contactdata.message !== "" &&
      contactdata.Skype !== ""
    ) {
    } else {
      toast("Please fill all the fields!!!", { type: "error" });
      return;
    }
    await addDoc(collection(db, "Contact"), {
      timestamp: serverTimestamp(),
      title: contactdata.name,
      Sub_title: contactdata.email,
      phone_no: contactdata.phone_no,
      Skype: contactdata.Skype,
      msg_subject: contactdata.msg_subject,
      message: contactdata.message,
    })
      .then(() => {
        toast("Your message send successfully", { type: "success" });
      })
      .catch((error) => {
        toast.error("Error adding Form");
      });
    setContactdata("");
    setTimeout(function () {
      window.location.reload(true);
    }, 5000);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <ToastContainer />
      <div className="career-modal">
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container">
              <h2 className="modal-header-career">
                <span className="sp-main">Contact Us</span>
              </h2>
              <div className="modal-header-detail">
                <p>
                  Get In Touch With Our Experts To Turn Your Idea Into Reality.
                </p>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="full_name">Full Name*</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required=""
                      value={contactdata.name}
                      onChange={(e) => handleChange(e)}
                      autoFocus={false}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="email">Email Address*</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      required=""
                      value={contactdata.email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="phone">Phone No.*</label>
                    <input
                      type="tel"
                      id="phone"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      name="phone_no"
                      className="form-control"
                      placeholder="Your Phone No."
                      required=""
                      value={contactdata.phone_no}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="phone">Subject*</label>
                    <input
                      type="text"

                      name="msg_subject"
                      className="form-control"
                      placeholder="Your Subject"
                      required=""
                      value={contactdata.msg_subject}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="address">Skype Us*</label>
                <input
                  type="text"
                  id="Skype"
                  name="Skype"
                  className="form-control"
                  placeholder="Your Skype Id"
                  required=""
                  value={contactdata.Skype}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Address*</label>
                <textarea
                  name="message"
                  value={contactdata.message}
                  className="form-control"
                  cols="30"
                  rows="8"
                  placeholder="Your Message"
                  onChange={(e) => handleChange(e)}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-block" onClick={handlePublish}>
              <i className="bx bx-right-arrow-circle" type="solid"></i>
              <span>Send Now</span>
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Skypemodal;
