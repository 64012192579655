// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAaPltvJY8j4ScaKDMWvLGH9cljPTBOqiw",
//   authDomain: "soft-holic.firebaseapp.com",
//   projectId: "soft-holic",
//   storageBucket: "soft-holic.appspot.com",
//   messagingSenderId: "990994442621",
//   appId: "1:990994442621:web:f6a6f032a5d21622a94414",
//   measurementId: "G-ZHF22M367N"
// };
const firebaseConfig = {
  apiKey: "AIzaSyBsq7hkvACUBqN7B_TgQOBt1ZpsB7medys",
  authDomain: "softholic-b8c89.firebaseapp.com",
  projectId: "softholic-b8c89",
  storageBucket: "softholic-b8c89.appspot.com",
  messagingSenderId: "744870894894",
  appId: "1:744870894894:web:122655d977a2ddc89caebf",
  measurementId: "G-WZC9SEHVFE",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);


export const storage = getStorage(app); 
export const db = getFirestore(app);
