import React from "react";
import "./Subbanner.css";

const Subbanner = ({pagename}) => {
  return (
    <section className="main-wrapper">
      <div className="background-overlay1"></div>
      <div className="column-gap-default">
        <div className="top-column">
          <div className="widget-wrap">
            <div className="heading-default">
              <div className="widget-container">
                <h1>{pagename}</h1>
              </div>
            </div>
            <div className="">
              <div className="widget-container">
                <p>
                  <a href="/">Home</a> / {pagename}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subbanner;
