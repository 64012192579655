import React from "react";
import "./Footer.css";
import logo from "../../assets/images/info-logot.png";

const Footer = () => {
  return (
    // <!-- ======= Footer ======= -->
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-info">
              <span><img src={logo}/></span>
              {/* <h3>Sunriseinfo</h3> */}
              {/* <p>We Build Your Brand</p> */}
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i> <a href="/">Home</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#about">About us</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Services</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#portfolio">Portfolio</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#gallery">Gallary</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our services</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Web Development</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#services">App Development</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Graphic Designing</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Cloud Consulting</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="#services">Management and Monitoring</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                410-413,Shanti Niketan Flora Buisness Point,Abrama Road,Surat-394101 {" "}
                <br />
                <br />
                <strong>Phone:</strong> +91 9979686333
                <br />
                <strong>Email:</strong> contect@sunriseinfo.in
                <br />
              </p>

              <div className="social-links">
                {/* <a href="/" className="twitter">
                  <i className="bi bi-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/sunriseinfo.india"
                  className="facebook"
                >
                  <i className="bi bi-facebook"></i>
                </a> */}
                <a
                  href="#"
                  className="instagram"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  href="#"
                  className="linkedin"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna veniam enim veniam illum dolore legam minim quorum culpa
                amet magna export quem marada parida nodela caramase seza.
              </p>
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div> */}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong>sunriseinfo</strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="/"></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
