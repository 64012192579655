import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Homepage";
import "bootstrap-icons/font/bootstrap-icons.css";
import Portfolio from "./components/Portfolio/Portfolio";
import "aos-animations/dist/animations.min.css";
import "aos-animations/dist/animations.min.js";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import javascript from "./components/main";
import "animate.css";
import Career from "./components/Career/Career";
import About from "./components/About/About1";
import Services from "./components/Servicess/Services";
import Contact from "./components/Contact/Contact";

function App() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getloading();
  }, []);

  const getloading = () => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };
  return (
    <>
      {loader && <div id="preloader"></div>}
      <Helmet>
        <script src={javascript} type="text/javascript" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
