import AOS from "aos";
// import Isotope from "isotope-layout";
// import Swiper from "swiper";

export default (function () {
  // ("use strict");

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  /**
   * Easy event listener function
   */
  // const on = (type, el, listener, all = false) => {
  //   let selectEl = select(el, all);
  //   if (selectEl) {
  //     if (all) {
  //       selectEl.forEach((e) => e.addEventListener(type, listener));
  //     } else {
  //       selectEl.addEventListener(type, listener);
  //     }
  //   }
  // };

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
  };

  // /**
  //  * Scrolls to an element with header offset
  //  */
  // const scrollto = (el) => {
  //   let header = select("#header");
  //   let offset = header.offsetHeight;

  //   if (!header.classList.contains("header-scrolled")) {
  //     offset -= 20;
  //   }

  //   let elementPos = select(el).offsetTop;
  //   window.scrollTo({
  //     top: elementPos - offset,
  //     behavior: "smooth",
  //   });
  // };

  /**
   * Navbar links active state on scroll
   */
  let navbarlinks = select("#navbar .scrollto", true);
  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      if (!navbarlink.hash) return;
      let section = select(navbarlink.hash);
      if (!section) return;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navbarlink.classList.add("active");
      } else {
        navbarlink.classList.remove("active");
      }
    });
  };
  window.addEventListener("load", navbarlinksActive);
  onscroll(document, navbarlinksActive);

  /**
   * Porfolio isotope and filter
   *
   */

  // window.addEventListener("load", () => {
  //   let portfolioContainer = select(".portfolio-container");
  //   if (portfolioContainer) {
  //     let portfolioIsotope = new Isotope(portfolioContainer, {
  //       itemSelector: ".portfolio-item",
  //     });

  //     let portfolioFilters = select("#portfolio-flters li", true);

  //     on(
  //       "click",
  //       "#portfolio-flters li",
  //       function (e) {
  //         e.preventDefault();
  //         portfolioFilters.forEach(function (el) {
  //           el.classList.remove("filter-active");
  //         });
  //         this.classList.add("filter-active");

  //         portfolioIsotope.arrange({
  //           filter: this.getAttribute("data-filter"),
  //         });
  //         portfolioIsotope.on("arrangeComplete", function () {
  //           AOS.refresh();
  //         });
  //       },
  //       true
  //     );
  //   }
  // });

  /**
   * Portfolio details slider
   */
  // new Swiper(".portfolio-details-slider", {
  //   speed: 400,
  //   loop: true,
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: false,
  //   },
  //   pagination: {
  //     el: ".swiper-pagination",
  //     type: "bullets",
  //     clickable: true,
  //   },
  // });

  /**
   * Animation on scroll
   */
  window.addEventListener("load", () => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  });

  /**
   * Initiate Pure Counter
   */
  // new PureCounter();
})();
