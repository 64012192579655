import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import {  query } from "firebase/firestore";

const blogCollectionRef = query(collection(db, "Career_skill"));

class BlogDataService {
  getData = () => {
    return getDocs(blogCollectionRef);
  };
}
export default new BlogDataService();
