import React from "react";
import "./Navbar.css";
import logo from "../../assets/images/info-logot.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const Navbar = () => {
  const Navigate = useNavigate();
  const [path, setPath] = useState('')

  useEffect(() => {
    let url = window.location.pathname
    setPath(url)
  }, [])


  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  const navbarOnclick = () => {

    document.getElementsByTagName("body")[0].classList.toggle("hideOverflow");
    select("#navbar").classList.toggle("navbar-mobile");
    select("#x-nav").classList.toggle("bi-x");

  };

  useEffect(() => {
    const navbar = document.querySelector(".header-transparent");
    window.onscroll = () => {
      if (window.scrollY > 100) {
        navbar?.classList?.add("header-scrolled");
      } 
      else {
        navbar?.classList?.remove("header-scrolled");
      } 
    };
  }, []);

  const Career = () => {
    Navigate("/Career");
  };
  const Home = () => {
    Navigate("/");
  };
  const About = () => {
    Navigate("/About");
  };
  const Services = () => {
    Navigate("/Services");
  };
  const Contact = () => {
    Navigate("/Contact");
  }

  return (
    <>
      <div>
        <header
          id="header"
          className="fixed-top d-flex align-items-center header-transparent"
        >
          <div className="container d-flex align-items-center">
            <h1 className="logo me-auto" onClick={Home}>
              <a href="/">
                <img src={logo} alt="" className="img-fluid" />
                Sunriseinfo</a>
            </h1>

            <nav id="navbar" className="navbar order-last order-lg-0">
              <ul id="activeid">
                <li>
                  <a className={`nav-link  ${path === '/' ? 'active' : ""}`} href=" / ">
                    Home
                  </a>
                </li>
                <li onClick={About}>
                  <a className={`nav-link  ${path === '/About' ? 'active' : ""}`} href={About}>
                    About
                  </a>
                </li>
                <li onClick={Services}>
                  <a className={`nav-link  ${path === '/Services' ? 'active' : ""}`} href={Services}>
                    Services
                  </a>
                </li>
               
                <li onClick={Career}>
                  <a className={`nav-link  ${path === '/Career' ? 'active' : ""}`} href={Career}>
                    Career
                  </a>
                </li>
                <li onClick={Contact}>
                  <a className={`nav-link  ${path === '/Contact' ? 'active' : ""}`} href={Contact}>
                    Contact
                  </a>
                </li>
              </ul>
              <i
                className="bi bi-list mobile-nav-toggle"
                onClick={navbarOnclick}
                id="x-nav"
              ></i>
            </nav>

            {/* <div className="social-links">
               <a href="/" className="twitter">
                <i className="bi bi-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/softholic.india"
                className="facebook"
              >
                <i className="bi bi-facebook"></i>
              </a> 
              <a
                href="https://www.linkedin.com/company/softholic-india/"
                className="linkedin"
              >
                <i className="bi bi-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/softholic.india/?hl=en"
                className="instagram"
              >
                <i className="bi bi-instagram"></i>
              </a>
            </div> */}
          </div>
        </header>
      </div >
    </>
  );
};

export default Navbar;
