import React from 'react'
import "./Specialties.css";
import php from "../../assets/images/Specialties/php.svg";
import java from "../../assets/images/Specialties/java.svg";
import net from "../../assets/images/Specialties/net.svg";
import nodejs from "../../assets/images/Specialties/nodejs.svg";
import python from "../../assets/images/Specialties/python.svg";

const Backend = () => {
  return (
    <div className="mt-4" data-aos="fade-up">
      <div className="justify-content-center row content_info" style={{ margin: 0 }}>
        <div className="resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={php}
                alt="Photoshop"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              PHP
            </h4>
          </div>
        </div>
        <div className="resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={java}
                alt="Figma"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Java
            </h4>
          </div>
        </div>
        <div className="resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={net}
                alt="Illustrator"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              .NET
            </h4>
          </div>
        </div>
        <div className="resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={nodejs}
                alt="Maya"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Node Js
            </h4>
          </div>
        </div>
        <div className="resize-img w-auto sp1">
          <div className="technology-box">
            <div className="center-img">
              <img
                src={python}
                alt="Max"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Python
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Backend