import React from "react";
import "./Specialties.css";
import apple from "../../assets/images/Specialties/apple.png";
import android from "../../assets/images/Specialties/android.png";
import react from "../../assets/images/Specialties/atom (1).png";
import flutter from "../../assets/images/Specialties/flutter.svg";
import swift from "../../assets/images/Specialties/swift.svg";
import kotlin from "../../assets/images/Specialties/kotlin.svg";

const Mobile = () => {
  return (
    <div
      className="mt-4"
      data-aos="fade-up"
      data-aos-offset="300"
      data-aos-easing="ease-in-sine"
      style={{ overflowX: "hidden" }}
    >
      <div className="justify-content-center row content_info" style={{ margin: 0 }}>
        <div
          className=" resize-img w-auto sp1"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
        >
          <div className="technology-box">
            <div className="center-img">
              <img
                src={apple}
                alt="Photoshop"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              ios
            </h4>
          </div>
        </div>
        <div
          className=" resize-img w-auto sp1"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-delay="300"
        >
          <div className="technology-box">
            <div className="center-img">
              <img
                src={android}
                alt="Figma"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Android
            </h4>
          </div>
        </div>
        <div
          className=" resize-img w-auto sp1"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-delay="500"
        >
          <div className="technology-box">
            <div className="center-img">
              <img
                src={react}
                alt="Illustrator"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              React Native
            </h4>
          </div>
        </div>
        <div
          className=" resize-img w-auto sp1"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-delay="700"
        >
          <div className="technology-box">
            <div className="center-img">
              <img
                src={flutter}
                alt="Maya"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Flutter
            </h4>
          </div>
        </div>
        <div
          className=" resize-img w-auto sp1"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-delay="900"
        >
          <div className="technology-box">
            <div className="center-img">
              <img
                src={swift}
                alt="Max"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Swift
            </h4>
          </div>
        </div>
        <div
          className=" resize-img w-auto sp1"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-delay="1000"
        >
          <div className="technology-box">
            <div className="center-img">
              <img
                src={kotlin}
                alt="Blender"
                data-aos=""
                width="130"
                height="130"
                className="wh-auto aos-init aos-animate"
              />
            </div>
            <h4 data-aos="" className="aos-init aos-animate">
              Kotlin
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
